import React, { useState } from 'react';
/* import { FaBars, FaTimes } from 'react-icons/fa'; */
import { RiMenuFill, RiCloseLargeFill } from 'react-icons/ri';
import { Link } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { toggleLanguage } from '../features/languageSlice';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  ); // Reads the selected language from the Redux store's state
  const dispatch = useDispatch(); // Initializes the dispatch function using the useDispatch hook to dispatch actions to the Redux store

  const handleLanguageToggle = () => {
    dispatch(toggleLanguage());
  };

  return (
    <section className='fixed flex sm:h-[80px] h-[50px] items-center justify-center w-full bg-[#0a192f] z-20'>
      <div className='fixed w-full max-w-[1440px] sm:h-[80px] h-[50px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 z-10'>
        {/* Main menu */}
        <ul className='hidden md:flex w-[336px] justify-between'>
          <li className='hover:text-[#686868] transition duration-300 px-4 cursor-pointer'>
            <Link to='home' smooth={true} duration={500}>
              {selectedLanguage === 'english' ? 'Home' : 'Hem'}
            </Link>
          </li>
          <li className='hover:text-[#686868] transition duration-300 px-4 cursor-pointer'>
            <Link to='about' smooth={true} duration={500}>
              {selectedLanguage === 'english' ? 'About' : 'Om'}
            </Link>
          </li>
          {/* <li className='hover:text-[#686868] transition duration-300 px-4 cursor-pointer'>
            <Link to='skills' smooth={true} duration={500}>
              {selectedLanguage === 'english' ? 'Skills' : 'Kompetenser'}
            </Link>
          </li> */}
          <li className='hover:text-[#686868] transition duration-300 px-4 cursor-pointer'>
            <Link to='projects' smooth={true} duration={500}>
              {selectedLanguage === 'english' ? 'Projects' : 'Projekt'}
            </Link>
          </li>
          <li className='hover:text-[#686868] transition duration-300 px-4 cursor-pointer'>
            <Link to='contact' smooth={true} duration={500}>
              {selectedLanguage === 'english' ? 'Contact' : 'Kontakt'}
            </Link>
          </li>
        </ul>

        {/* Hamburger menu button */}
        {/* If false show ☰ button, if true show X button */}
        <div onClick={handleClick} className='z-10 cursor-pointer md:hidden'>
          {!nav ? (
            <RiMenuFill className='w-[32px] h-[32px]' />
          ) : (
            <RiCloseLargeFill className='w-[28px] h-[28px]' />
          )}
        </div>

        {/* Mobile menu */}
        {/* If false hide menu, if true show menu */}
        <ul
          className={
            !nav
              ? 'hidden'
              : 'mobile-menu absolute top-0 left-0 w-full h-screen bg-[#2f0a24] flex flex-col justify-center items-center'
          }
        >
          <li className='py-6 text-2xl'>
            <Link
              onClick={handleClick}
              to='home'
              smooth={true}
              duration={500}
              className='cursor-pointer'
            >
              {selectedLanguage === 'english' ? 'Home' : 'Hem'}
            </Link>
          </li>
          <li className='py-6 text-2xl'>
            <Link
              onClick={handleClick}
              to='about'
              smooth={true}
              duration={500}
              className='cursor-pointer'
            >
              {selectedLanguage === 'english' ? 'About' : 'Om'}
            </Link>
          </li>
          <li className='py-6 text-2xl'>
            <Link
              onClick={handleClick}
              to='projects'
              smooth={true}
              duration={500}
              className='cursor-pointer'
            >
              {selectedLanguage === 'english' ? 'Projects' : 'Projekt'}
            </Link>
          </li>
          <li className='py-6 text-2xl'>
            <Link
              onClick={handleClick}
              to='contact'
              smooth={true}
              duration={500}
              className='cursor-pointer'
            >
              {selectedLanguage === 'english' ? 'Contact' : 'Kontakt'}
            </Link>
          </li>
        </ul>

        {/* Language button */}
        <button
          className='px-2 py-[2px] ml-4 text-sm font-semibold text-blue-700 transition duration-300 bg-transparent border border-blue-500 rounded sm:py-1 sm:w-16 sm:text-base hover:bg-blue-500 hover:text-white hover:border-transparent'
          onClick={handleLanguageToggle}
        >
          {selectedLanguage === 'english' ? 'Swe' : 'Eng'}
        </button>
      </div>
    </section>
  );
};

export default Navbar;
