import React from 'react';
import WeatherAppImg from '../assets/portfolio-images/weather-app.png';
import SequenceNumbersGame from '../assets/portfolio-images/sequence-numbers-game.png';
import IscanoAbImg from '../assets/portfolio-images/iscano-ab.png';
import MemoryGameImg from '../assets/portfolio-images/memory-game.png';
import ToDoAppImg from '../assets/portfolio-images/todo-app.png';
import { useSelector } from 'react-redux';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
/* import SwiperCore from 'swiper/core'; */

// Install Swiper modules
/* SwiperCore.use([Navigation]); */

const Projects = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  ); // Extracts language from the Redux store state and assigning it to the selectedLanguage variable for use within the component

  return (
    <div
      name='projects'
      className='w-full text-gray-300 bg-[#0a192f] h-screen flex items-center'
    >
      <div className='max-w-[1100px] mx-auto p-4 w-full z-10'>
        <div className='z-10 pb-8'>
          <h2 className='inline text-4xl font-bold text-gray-300 border-b-4 border-pink-600'>
            {selectedLanguage === 'english' ? 'Projects' : 'Projekt'}
          </h2>
          <p className='py-6'>
            {selectedLanguage === 'english'
              ? 'Check out some of my recent work'
              : 'Ta en titt på några av mina senaste arbeten'}
          </p>
        </div>{' '}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={20}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            // Define breakpoints for different screen sizes
            1024: {
              slidesPerView: 2, // Change to one slide per view for screens less than or equal to 768px wide
            },
          }}
        >
          <SwiperSlide>
            {/* Grid item 1 */}
            <div className='lg:h-72 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto grid-item relative'>
              <img
                src={WeatherAppImg}
                alt='Weather app demo'
                className='object-cover h-full transition-transform transform image-1'
              />
              {/* Hover effects */}
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center duration-200 bg-purple-500 bg-opacity-100 opacity-0 group-hover:opacity-100'>
                <p className='text-2xl font-bold tracking-wider text-white max-sm:text-xl'>
                  Weather App
                </p>
                <p className='mt-4 font-bold tracking-wider text-white'>
                  React &emsp; Sass
                </p>
                <div className='pt-8 text-center'>
                  <a
                    href='https://phenomenal-starship-afc3f8.netlify.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 mr-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:mr-8 sm:px-3 sm:py-2'>
                      Demo
                    </button>
                  </a>
                  <a
                    href='https://github.com/IgorGryp/weather-app-react'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:px-3 sm:py-2'>
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {/* Grid item 2*/}
            <div className='lg:h-72 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto grid-item relative'>
              <img
                src={ToDoAppImg}
                alt=''
                className='object-cover w-full h-full transition-transform transform image-1'
              />
              {/* Hover effects */}
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center duration-200 bg-purple-500 bg-opacity-100 opacity-0 group-hover:opacity-100'>
                <p className='text-2xl font-bold tracking-wider text-white max-sm:text-xl'>
                  To Do List
                </p>
                <p className='mt-4 font-bold tracking-wider text-white'>
                  React
                </p>
                <div className='pt-8 text-center'>
                  <a
                    href='https://luminous-cajeta-bc66ed.netlify.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 mr-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:mr-8 sm:px-3 sm:py-2'>
                      Demo
                    </button>
                  </a>
                  <a
                    href='https://github.com/IgorGryp/to-do-list-app-react'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:px-3 sm:py-2'>
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {/* Grid item 3*/}
            <div className='lg:h-72 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto grid-item relative'>
              <img
                src={IscanoAbImg}
                alt=''
                className='object-cover w-full h-full transition-transform transform image-1'
              />
              {/* Hover effects */}
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center duration-200 bg-purple-500 bg-opacity-100 opacity-0 group-hover:opacity-100'>
                <p className='text-2xl font-bold tracking-wider text-white max-sm:text-xl'>
                  Landing Page
                </p>
                <p className='mt-4 font-bold tracking-wider text-white'>
                  JS &emsp; Bootstrap
                </p>
                <div className='pt-8 text-center'>
                  <a
                    href='https://comforting-nasturtium-b300dd.netlify.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 mr-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:mr-8 sm:px-3 sm:py-2'>
                      Demo
                    </button>
                  </a>
                  <a
                    href='https://github.com/IgorGryp/iscano-js'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:px-3 sm:py-2'>
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {/* Grid item 4*/}
            <div className='lg:h-72 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto grid-item relative'>
              <img
                src={SequenceNumbersGame}
                alt=''
                className='object-cover w-full h-full transition-transform transform image-1'
              />
              {/* Hover effects */}
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center duration-200 bg-purple-500 bg-opacity-100 opacity-0 group-hover:opacity-100'>
                <p className='text-2xl font-bold tracking-wider text-white max-sm:text-xl'>
                  Sequence Numbers Game
                </p>
                <p className='mt-4 font-bold tracking-wider text-white'>JS</p>
                <div className='pt-8 text-center'>
                  <a
                    href='https://guileless-zabaione-0d0707.netlify.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 mr-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:mr-8 sm:px-3 sm:py-2'>
                      Demo
                    </button>
                  </a>
                  <a
                    href='https://github.com/IgorGryp/sequence-numbers-game-js'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:px-3 sm:py-2'>
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {/* Grid item 5*/}
            <div className='lg:h-72 shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto grid-item relative'>
              <img
                src={MemoryGameImg}
                alt=''
                className='object-cover w-full h-full transition-transform transform image-1'
              />
              {/* Hover effects */}
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center duration-200 bg-purple-500 bg-opacity-100 opacity-0 group-hover:opacity-100'>
                <p className='text-2xl font-bold tracking-wider text-white max-sm:text-xl'>
                  Memory Game
                </p>
                <p className='mt-4 font-bold tracking-wider text-white'>JS</p>
                <div className='pt-8 text-center'>
                  <a
                    href='https://silly-lily-1ee047.netlify.app/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 mr-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:mr-8 sm:px-3 sm:py-2'>
                      Demo
                    </button>
                  </a>
                  <a
                    href='https://github.com/IgorGryp/weather-app-react'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button className='px-2 py-1 text-lg font-bold text-center text-gray-700 bg-white rounded-lg sm:px-3 sm:py-2'>
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Card container */}
        {/* <div className="grid gap-4 sm:grid-cols-2"></div> */}
      </div>
    </div>
  );
};

export default Projects;
