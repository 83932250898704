import { useSelector } from 'react-redux';
import HTML from '../assets/skills-icons/html.png';
import CSS from '../assets/skills-icons/css.png';
import JavaScript from '../assets/skills-icons/javascript.png';
import TypeScript from '../assets/skills-icons/typescript.png';
import ReactImg from '../assets/skills-icons/react.png';
import Redux from '../assets/skills-icons/redux.png';
import Bootstrap from '../assets/skills-icons/bootstrap5.png';
import Tailwind from '../assets/skills-icons/tailwind.png';
/* import Node from '../assets/skills-icons/node.png';
import Express from '../assets/skills-icons/express-white.png';
import MongoDB from '../assets/skills-icons/mongodb.png'; */
import Firebase from '../assets/skills-icons/firebase.png';
import GitHub from '../assets/skills-icons/github.png';

const About = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  ); // Extracts language from the Redux store state and assigning it to the selectedLanguage variable for use within the component

  return (
    <div name='about' className='w-full bg-[#0a192f] text-gray-300 h-screen'>
      <div className='flex flex-col items-center justify-center w-full h-full p-4'>
        <div className='max-w-[1100px] w-full grid grid-cols-2 gap-8'>
          <div className='z-10 pb-8'>
            <h2 className='inline text-4xl font-bold border-b-4 border-pink-600'>
              {selectedLanguage === 'english' ? 'About' : 'Om'}
            </h2>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1100px] w-full grid sm:grid-cols-1 gap-8'>
          <div className='z-10 md:h-20 h-44'>
            <p>
              {selectedLanguage === 'english'
                ? 'My greatest interest lies in creating fantastic websites and web applications that truly make a difference for people. I use my skills to build digital platforms that not only meet users needs but also make their online experiences smooth and satisfying.'
                : 'Mitt största intresse är att skapa fantastiska webbplatser och webbapplikationer som verkligen gör skillnad för människor. Jag använder mina kunskaper för att bygga digitala plattformar som inte bara möter användarnas behov utan även gör deras online-upplevelser smidiga och tillfredsställande.'}
            </p>
          </div>
          {/* Icons container */}
          <h3 className='font-extrabold'>Tech Stack</h3>
          <div className='grid w-full grid-cols-5 gap-2 text-center md:grid-cols-10'>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={HTML}
                className='w-8 mx-auto my-2 md:w-10'
                alt='HTML icon'
              />
              <p className='hidden my-2 text-xs sm:block'>HTML</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={CSS}
                className='w-8 mx-auto my-2 md:w-10'
                alt='CSS icon'
              />
              <p className='hidden my-2 text-xs sm:block'>CSS</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={JavaScript}
                className='w-8 mx-auto my-2 md:w-10'
                alt='JavaScript icon'
              />
              <p className='hidden my-2 text-xs sm:block'>JavaScript</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={TypeScript}
                className='w-8 mx-auto my-2 md:w-10'
                alt='TypeScript icon'
              />
              <p className='hidden my-2 text-xs sm:block'>TypeScript</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={ReactImg}
                className='w-8 mx-auto my-2 md:w-10'
                alt='React icon'
              />
              <p className='hidden my-2 text-xs sm:block'>React</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={Redux}
                className='w-8 mx-auto my-2 md:w-10'
                alt='Redux icon'
              />
              <p className='hidden my-2 text-xs sm:block'>Redux</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={Bootstrap}
                className='w-8 mx-auto my-2 md:w-10'
                alt='Bootstrap icon'
              />
              <p className='hidden my-2 text-xs sm:block'>Bootstrap</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={Tailwind}
                className='w-8 mx-auto my-2 md:w-10'
                alt='Tailwind icon'
              />
              <p className='hidden my-2 text-xs sm:block'>Tailwind</p>
            </div>
            {/* <div className="shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]">
              <img src={Node} className="w-10 mx-auto my-2" alt="Node JS icon" />
              <p className="my-2 text-xs">Node JS</p>
            </div>
            <div className="shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]">
              <img src={Express} className="w-10 mx-auto my-2" alt="Express JS icon" />
              <p className="my-2 text-xs">Express</p>
            </div>
            <div className="shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]">
              <img src={MongoDB} className="w-10 mx-auto my-2" alt="MongoDB icon" />
              <p className="my-2 text-xs">MongoDB</p>
            </div> */}
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={Firebase}
                className='w-8 mx-auto my-2 md:w-10'
                alt='Firebase icon'
              />
              <p className='hidden my-2 text-xs sm:block'>Firebase</p>
            </div>
            <div className='shadow-md z-10 shadow-[#040c16] hover:scale-110 duration-500 bg-[#0a192f]'>
              <img
                src={GitHub}
                className='w-8 mx-auto my-2 md:w-10'
                alt='GitHub icon'
              />
              <p className='hidden my-2 text-xs sm:block'>GitHub</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
