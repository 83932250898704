import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import myPhoto from '../assets/photo//protfolio-img-transformed-01.jpeg';

const Home = () => {
  const selectedLanguage = useSelector(
    (state) => state.language.selectedLanguage
  ); // Extracts language from the Redux store state and assigning it to the selectedLanguage variable for use within the component

  return (
    <div
      name='home'
      className='w-full h-screen bg-[#0a192f] flex justify-center items-center'
    >
      <div className='max-w-[1100px] mx-auto p-4 sm:px-8 flex flex-col justify-center h-full'>
        <div className='md:flex'>
          <div className='flex flex-col justify-center md:w-3/5'>
            {/* Welcome text */}
            <p className='z-10 text-pink-600'>
              {selectedLanguage === 'english'
                ? 'Hi, my name is'
                : 'Hej, jag heter'}
            </p>

            <h1 className='text-4xl sm:text-5xl md:text-6xl font-bold text-[#ccd6f6] z-10'>
              Igor Grypishyn
            </h1>

            <h2 className='text-[#8892b0] py-4 md:text-2xl sm:text-xl text-lg z-10 max-w-xl'>
              {selectedLanguage === 'english'
                ? "I'm a passionate Front end developer from Stockholm."
                : 'Jag är en passionerad Frontendutvecklare från Stockholm.'}
            </h2>
            {/* Contact links */}
            <div className='absolute position-initial flex top-[90%] sm:mt-8 left-[50%] md:left-[0] md:ml-[0px] ml-[-136px] z-10'>
              <ul className='flex justify-between'>
                <li className='cursor-pointer mr-8 w-[44px] h-[44px] flex justify-center items-center duration-300 bg-[#2563EB] rounded-full'>
                  <a
                    href='https://www.linkedin.com/in/igor-grypishyn-6b9998204'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center justify-center w-full text-gray-300'
                  >
                    <FaLinkedin size={26} />
                  </a>
                </li>
                <li className='cursor-pointer mr-8 w-[44px] h-[44px] flex justify-center items-center duration-300 bg-[#333333] rounded-full'>
                  <a
                    href='https://github.com/IgorGryp'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center justify-center w-full text-gray-300'
                  >
                    <FaGithub size={26} />
                  </a>
                </li>
                <li className='cursor-pointer mr-8 w-[44px] h-[44px] flex justify-center items-center duration-300 bg-[#6fc2b0] rounded-full'>
                  <a
                    href='mailto:igor.gryp@gmail.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center justify-center w-full text-gray-300'
                  >
                    <HiOutlineMail size={26} />
                  </a>
                </li>
                <li className='cursor-pointer w-[44px] h-[44px] flex justify-center items-center duration-300 bg-[#565f69] rounded-full sm:hover:w-[140px] overflow-hidden relative'>
                  <a
                    href='https://drive.google.com/file/d/1RpQNg4gK75L1YPnoS1qa1mMpvVJ1-LuX/view?usp=sharing'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center justify-center w-full text-gray-300'
                  >
                    <BsFillPersonLinesFill
                      size={26}
                      className='absolute left-2'
                    />
                    <span className='absolute w-full left-14'>
                      {selectedLanguage === 'english' ? 'Resume' : 'CV'}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Photo */}
          <div className='z-10 flex items-center justify-center p-10 md:w-2/5'>
            <img
              src={myPhoto}
              alt='Igor'
              className='z-10 w-48 rounded-full sm:w-64 md:w-80 portfolio-img'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
